<style lang="less" scoped>
#memberlist {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .select-status {
    width: 150px;
    margin-right: 14px;
  }
  .model-action {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(68, 125, 245, 1);
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>
<template>
  <div class="memberlist" id="memberlist">
    <div class="tab-top">
      <table-title>
        <p slot="custom">（总数：{{reg_total}}）</p>
      </table-title>
      <table-action alias="member" :hasSearch="false" :hasAdd="false">
        <member-filter @filterHandler="filterHandler"></member-filter>
      </table-action>
    </div>
    <a-table
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :pagination="{
        current, 
        total,
        pageSize: size, 
      }"
      @change="tabChange"
      style="margin-top: 13px"
    >
      <div slot-scope="record" slot="action">
        <span class="model-action" @click="onEdit(record.member_id)">查看</span>
        <a-divider type="vertical" />
        <member-log :member_id="record.member_id" :member_name="record.member_name"></member-log>
      </div>
    </a-table>
  </div>
</template>

<script>
import { fetchMemberlist } from "@/api/member";
const columns = [
  {
    title: "会员ID",
    dataIndex: "member_id",
  },
  {
    title: "会员名称",
    dataIndex: "member_name",
  },
  {
    title: "会员手机号",
    dataIndex: "phone_number",
  },
  {
    title: "性别",
    dataIndex: "gender_text",
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  }
];

export default {
  name: "MemberList",
  components: {
    "member-filter": resolve =>
      require(["@/components/model/MemberFilter.vue"], resolve),
    "member-log": resolve =>
      require(["@/components/model/MemberLog.vue"], resolve),
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
    "table-action": resolve =>
      require(["@/components/table/TableAction.vue"], resolve),
  },
  data() {
    return {
      data: [],
      reg_total: undefined,
      columns,
      total: 10,
      size: 10,
      current: 1,
      loading: true,
      filterData: {},
    };
  },
  created() {
    this.getMemberlist();
  },
  methods: {
    async getMemberlist() {
      const that = this;
      this.loading = true;
      try {
        let res = await fetchMemberlist({
          page_size: this.size,
          page: this.current,
          gender: this.filterData.gender,
          birthday: this.filterData.birthday,
          member_id: this.filterData.member_id,
          city: this.filterData.city
        });
        if (!res) return;
        this.loading = false;
        this.reg_total = res.reg_total;
        this.total = res.total;
        this.data = res.member_list.map((item, index) => {
          switch (item.gender) {
            case 1: 
              item.gender_text = "男";
              break;
            case 2: 
              item.gender_text = "女";
              break;
            default:
              break;
          }
          item["key"] = item.member_id;
          return item;
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    tabChange(pagination, filters, sorter, { currentDataSource }) {
      let { current, pageSize } = pagination;
      this.current = current;
      this.size = pageSize;
      this.getMemberlist();
    },
    onEdit(memberId) {
      this.$router.push({ name: "memberDetail", query: { memberId: memberId }});
    },
    /// 筛选
    filterHandler(e) {
      this.filterData = e;
      this.current = 1;
      this.getMemberlist()
    },
  }
};
</script>
